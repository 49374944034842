<template>
  <v-sheet color="transparent">
    <Toolbar2 />
    <Hero />
  </v-sheet>
</template>

<script>
import Hero from '@/components/ui/landing/hero/HeroComponent.vue'
import Toolbar2 from '@/components/ui/landing/toolbar/Toolbar2.vue'

export default {
  components: {
    Hero,
    Toolbar2
  }
}
</script>
