<template>
  <v-sheet>
    <v-container>
      <v-row class="mt-1">
        <v-col lg="9">
          <h1>Stand out by keeping your brand</h1>
          <p>Create, share and use links from your own domain, keeping your business brand in tact. This establishes trust of the users by establishing who owns the link they are seeing.</p>
        </v-col>
        <v-col lg="3">
          <v-img :src="require('@/assets/images/features/brand.png')" alt="Brand" contain></v-img>
        </v-col>
      </v-row>
      <v-row>
        <v-col lg="3">
          <v-img :src="require('@/assets/images/features/tls.png')" alt="TLS"></v-img>
        </v-col>
        <v-col lg="9">
          <h1>Secured Communications</h1>
          <p>Rest easy knowing your Addysnip links are protected with encryption, maximizing the protection for you and your users from the bad guys. We create SSL certificates for you so you don't need to worry about procuring and providing a certificate.</p>
        </v-col>
      </v-row>
      <v-row>
        <v-col lg="8">
          <h1>Integration and Automation</h1>
          <p>In today's world of GitOps, CI/CD pipelines and infrastructure-as-code, why should you have to use a Graphical User Interface or Web Application to create and manage links? At AddySnip, we believe in automation. Unlike our competition, all of our paid plans come with unlimited access to our API as well as access to integrations.</p>
          <p>Currently we are integrated with Zapier through invites, a link is provided in the Client Portal. We are pursuing other integrations as well to allow easier and nearly code-free integration with your business' products. For more advanced users, you'll have access to our API and OpenAPI 2.0 specification to quickly integrate with our services.</p>
        </v-col>
        <v-col lg="4">
          <v-img :src="require('@/assets/images/features/integration.png')" alt="Integration"></v-img>
        </v-col>
      </v-row>
      <v-row>
        <v-col lg="3">
          <v-img :src="require('@/assets/images/features/analytics.png')" alt="Analytics"></v-img>
        </v-col>
        <v-col lg="9">
          <h1>Track Your Visitors</h1>
          <p>Understand what content is resonating with your audience, where your audience is from, and information about their devices. We'll gather where they clicked from, their geographical data, device and browser so that you can learn your top referrals and how your visitors are consuming your information on our Pro plans. All plans come with click counters.</p>
        </v-col>
      </v-row>
    </v-container>
  </v-sheet>
</template>

<script>
export default {
  name: 'Features'
}
</script>

<style>

</style>
