<template>
  <div>
    <Hero />

    <Features />
    <CallToAction
      title="Ready to talk?"
      subtitle="Our team is here to help."
      btn1-label="Contact Us"
      btn1-link="/contact"
      btn2-label="See Our Plans"
      btn2-link="/plans"
    />
    <Footer4Demo />
  </div>
</template>

<script>
import Hero from '@/components/ui/landing/hero/Hero.vue'
import Footer4Demo from '@/components/ui/landing/footer/Footer4Demo.vue'
import Features from '@/components/ui/landing/feature/Features.vue'
import CallToAction from '@/components/ui/landing/call-to-action/CallToAction.vue'

export default {
  name: 'AboutPage',
  components: {
    Hero,
    Footer4Demo,
    CallToAction,
    Features
  }
}
</script>

<style></style>
